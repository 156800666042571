<template>
    <el-dialog
            :title="'查看日志'"
            :visible.sync="dialogVisible"
            width="1024px"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">

            <el-form-item label="操作员名称" prop="operUserName" label-width="100px">
                <el-input v-model="editForm.operUserName" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="记录时间" prop="createTime" label-width="100px">
                <el-input v-model="editForm.createTime" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="ip" prop="operIp" label-width="100px">
                <el-input v-model="editForm.operIp" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="模块" prop="module" label-width="100px">
                <el-input v-model="editForm.module" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="操作描述" prop="operDesc" label-width="100px">
                <el-input v-model="editForm.operDesc" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="操作类型" prop="operType" label-width="100px">
                <el-input v-model="editForm.operType" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="是否异常" prop="isAbnormal" label-width="100px" >
                <el-radio-group v-model="editForm.isAbnormal"  disabled>
                    <el-radio :label="0">正常</el-radio>
                    <el-radio :label="1">异常</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="日志来源" prop="isManage" label-width="100px" >
                <el-radio-group v-model="editForm.isManage"  disabled>
                    <el-radio :label="0">其他</el-radio>
                    <el-radio :label="1">管理端</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="路径" prop="operUri" label-width="100px">
                <el-input v-model="editForm.operUri" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="请求方法名" prop="operMethod" label-width="100px">
                <el-input v-model="editForm.operMethod" autocomplete="off" disabled></el-input>
            </el-form-item>
            <el-form-item label="请求参数" prop="operRequParam" label-width="100px">
                <el-input v-model="editForm.operRequParam" autocomplete="off" disabled type="textarea" autosize ></el-input>
            </el-form-item>
            <el-form-item label="返回结果" prop="operResParam" label-width="100px">
                <el-input v-model="editForm.operResParam" type="textarea" autocomplete="off" autosize  disabled></el-input>
            </el-form-item>
            <el-form-item label="异常名称" prop="excName" label-width="100px" v-if="editForm.isAbnormal==1">
                <el-input v-model="editForm.excName" autocomplete="off" disabled autosize ></el-input>
            </el-form-item>
            <el-form-item label="异常信息" prop="excMessage" label-width="100px" v-if="editForm.isAbnormal==1">
                <el-input v-model="editForm.excMessage" autocomplete="off" type="textarea" autosize  disabled></el-input>
            </el-form-item>
            <el-form-item align="center">
                <el-button @click="resetForm('editForm')" >重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    export default {
        name: "SeeLog",
        data(){
            return{
                editForm:{
                },
                dialogVisible:false,
                editFormRules: {},
            }
        },
        methods:{
            change(val) {
                this.editForm.publishingContent=val;
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
            },
            handleClose() {
                this.resetForm('editForm')
            },
            init(id){
                this.dialogVisible=true;
                if (id!=null&&id!=''&&id>0){
                    this.$axios.post('/admin/logs/getLogInfo' ,{id:id}).then(res => {
                        this.editForm = res.data
                    })
                }
            },
        }
    }
</script>


<style scoped>

</style>
